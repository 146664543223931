import { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaTimes } from 'react-icons/fa';

const fadeIn = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.1s ease-in;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: #fff;
  padding: 20px;
  text-align: center;
  max-width: 600px;
  width: 100%;
  display: flex;
`;

const CloseIcon = styled(FaTimes)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  z-index: 10;
`;

const Modal = ({ children, onClose }) => {
  useEffect(() => {
    const closeOnEsc = event => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', closeOnEsc);
    return () => {
      window.removeEventListener('keydown', closeOnEsc);
    };
  }, [onClose]);

  const onContainerClick = event => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <ModalContainer onClick={onContainerClick}>
      <ModalContent>
        <CloseIcon onClick={onClose} />
        {children}
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;
