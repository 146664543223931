import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.div`
  height: 90px;
  width: 100%;
  background-color: #753bbd;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d0d0d0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.03em;
`;

export const Footer = () => {
  return <StyledFooter>2024 © Dajana Rodriguez</StyledFooter>;
};
