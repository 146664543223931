import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from './Modal';
import { TbClick, TbZoomQuestion } from 'react-icons/tb';

// Add styled components for title and text
const Title = styled.h2`
  font-size: 40px;
  color: #753bbd;
  line-height: 1.25;
  margin: 0;

  @media only screen and (max-width: 968px) {
    font-size: 32px;
  }
`;

const Text = styled.p`
  max-width: 666px;
  line-height: 1.45;
`;

const GridContainer = styled.div`
  padding: 16px 0;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);

  @media only screen and (max-width: 578px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ImageContainer = styled.div`
  overflow: hidden;
  grid-row-end: span ${props => (props.large ? '2' : '1')};
  aspect-ratio: ${props => (props.large ? '1/2' : '1/1')};
  width: 100%;
  height: 100%;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  background: #b9a3ce;

  &:hover {
    transform: scale(1.05);
  }
`;

const Thumbnail = styled.img`
  width: 250px;
  height: 250px;
  object-fit: cover;
  margin-left: auto;

  @media only screen and (max-width: 578px) {
    width: 100%;
    height: 100%;
    max-width: 250px;
    max-height: 250px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
  max-height: 80vh;
`;

const ModalImageAndDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;

  @media only screen and (max-width: 578px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const JobAndPositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
  height: 100%;

  @media only screen and (max-width: 578px) {
    text-align: center;
  }

  h3 {
    font-size: 22px;
    color: #753bbd;
    margin: 0;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    margin: 0;
  }
`;

const ZoomIcon = styled(TbZoomQuestion)`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 30px;
  color: #d0a6ff;
`;

const ImageGrid = ({ images = [] }) => {
  const [bufferIndex, setBufferIndex] = useState(null);

  const closeModal = () => {
    setBufferIndex(null);
    document.body.style.overflow = 'auto'; //unlock the scroll
  };

  useEffect(() => {
    if (bufferIndex != null) document.body.style.overflow = 'hidden'; //lock the scroll
    return () => {
      document.body.style.overflow = 'auto'; //unlock the scroll when unmount component
    };
  }, [bufferIndex]);

  return (
    <>
      <Title>Hlasy silných žien</Title>
      <Text>
        Spoznajte silné ženy, ktoré sa spojili s našou značkou a postavili sa za náš projekt. Ich inšpirujúce príbehy a myšlienky odhaľujú skutočnú silu a odvahu, čím motivujú ženy a hovoria im, že BUĎ silná, odvážná a neboj sa ísť za
        svojimi snami.
      </Text>
      <GridContainer>
        {images.map(({ src, alt, large, caption }, i) => (
          <ImageContainer
            key={i}
            large={large}
            onClick={() => {
              setBufferIndex(i);
              document.body.style.overflow = 'hidden'; //lock the scroll
            }}
          >
            <Image src={src} alt={alt || `Image ${i}`} />
            <ZoomIcon />
          </ImageContainer>
        ))}
        {bufferIndex != null && (
          <Modal onClose={closeModal}>
            <ModalContent>
              <ModalImageAndDescription>
                <JobAndPositionContainer>
                  <h3>{images[bufferIndex].name}</h3>
                  <p>{images[bufferIndex].job}</p>
                </JobAndPositionContainer>
                <Thumbnail src={images[bufferIndex].modalSrc} alt="Modal" />
              </ModalImageAndDescription>
              {images[bufferIndex].answers}
            </ModalContent>
          </Modal>
        )}
      </GridContainer>
    </>
  );
};

export default ImageGrid;
