import { useInView } from 'react-intersection-observer';
import styled, { keyframes } from 'styled-components';
import { useState } from 'react';

const fadeIn = keyframes`
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Text = styled.div`
  &.fade-in {
    animation: ${fadeIn} 2s ease-out;
  }
`;

const Container = styled.div`
  display: flex;
  gap: 10px;
  font-size: ${({ fontSize }) => fontSize || '48px'};
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.5;
  color: #753bbd;
  z-index: -1;
  pointer-events: none;

  @media (max-width: 568px) {
    font-size: 24px !important;
    opacity: 0.2;
  }

  @media (max-width: 768px) {
    ${({ mobilePosition }) =>
      mobilePosition &&
      `
      position: absolute;
      ${mobilePosition.left !== undefined ? `left: ${mobilePosition.left};` : ''}
      ${mobilePosition.top !== undefined ? `top: ${mobilePosition.top};` : ''}
      ${mobilePosition.right !== undefined ? `right: ${mobilePosition.right};` : ''}
      ${mobilePosition.bottom !== undefined ? `bottom: ${mobilePosition.bottom};` : ''}
    `}
  }

  ${({ position }) =>
    position &&
    `
    position: absolute;
    ${position.left !== undefined ? `left: ${position.left};` : ''}
    ${position.top !== undefined ? `top: ${position.top};` : ''}
    ${position.right !== undefined ? `right: ${position.right};` : ''}
    ${position.bottom !== undefined ? `bottom: ${position.bottom};` : ''}
  `}
`;

function FadeInText(props) {
  const { position, mobilePosition, children, fontSize } = props;
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const [scrollPercent] = useState(0);

  return (
    <Container position={position} mobilePosition={mobilePosition} scrollPercent={scrollPercent} fontSize={fontSize}>
      <Text>Buď...</Text>
      <Text className={inView ? 'fade-in' : ''} ref={ref}>
        {children}
      </Text>
    </Container>
  );
}

export default FadeInText;
