import styled, { createGlobalStyle } from 'styled-components';
import { Toaster } from 'react-hot-toast';
import { normalize } from 'styled-normalize';
import { HeroSection } from './components/HeroSection';
import { Footer } from './components/Footer';
import ImageSection from './components/ImageSection';
import ImageGrid from './components/ImageGrid';
import FadeInText from './components/FadeInText';
import logoSvg from './img/logo.svg';

const GlobalStyle = createGlobalStyle`
    ${normalize}
    * {
        box-sizing: border-box;
    }

    html {
        background-color: rgb(255, 252, 246);
    }

    body {
        padding: 0;
    }
`;

const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
`;

const WebContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 2400px;
  margin: 0 auto;
  overflow: hidden;
  background: #fffcf6;
  position: relative;
  z-index: 1;
`;

const TextContainer = styled.p`
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin-bottom: 6px;
`;

const AnswerContainer = styled.p`
  width: 100%;
  text-align: left;
  font-weight: normal;
  margin-top: 6px;
`;

const Logo = styled.img`
  width: 50%;
  max-height: 150px;
`;

const LogoButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 272px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
    height: auto;
    margin-bottom: 24px;
    margin-top: 12px;

    ${Logo} {
      width: 100%;
      max-height: 100px;
    }
  }
`;

const CTASection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Button = styled.a`
  background-color: #753bbd;
  color: white;
  padding: 16px 32px;
  text-decoration: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #5e2f9b;
  }

  @media only screen and (max-width: 578px) {
    font-size: 16px;
  }
`;

function App() {
  const publicPath = '.';
  let imagesBuffer = [
    {
      src: `${publicPath}/fototext/0.jpg`,
      modalSrc: `${publicPath}/foto/0.jpg`,
      alt: 'Image 1',
      large: false,
      name: 'Alexandra Bobríková',
      job: 'Právnička',
      answers: [
        <>
          <TextContainer>Situácia v ktorej sa cítite najsilnejšia?</TextContainer>
          <AnswerContainer>„Najsilnejšia sa cítim ako matka, ktorá rieši rôzne náročné situácie v živote svojho dieťaťa a keď sa snažím byť svojmu dieťaťu čo najlepším vzorom do života. “</AnswerContainer>
        </>,
      ],
    },
    {
      src: `${publicPath}/fototext/1.jpg`,
      modalSrc: `${publicPath}/foto/1.jpg`,
      alt: 'Image 2',
      large: true,
      name: 'Zuzana Vačková',
      job: 'Herečka',
      answers: [
        <>
          <TextContainer>Čo pre Vás predstavuje ženskú silu?</TextContainer>
          <AnswerContainer>„Ženskú silu cítim v sebe, ale milujem aj to, keď cítim ženskú podporu, vtedy sa moja vnútorná ženská sila zdvojnásobuje.”</AnswerContainer>
          <TextContainer>Situácia v ktorej sa cítite najsilnejšia?</TextContainer>
          <AnswerContainer>„Ja si to, že som silná žena, uvedomujem v každodenných maličkostiach, pretože ten každodenný život je plný výziev…”</AnswerContainer>
          <TextContainer>Prečo máte radi značku Dajana Rodriguez?</TextContainer>
          <AnswerContainer>„Dajana Rodriguez pre mňa znamená originalitu, ženskú podporu, made in Slovakia, krásu a eleganciu.”</AnswerContainer>
        </>,
      ],
    },
    {
      src: `${publicPath}/fototext/2.jpg`,
      modalSrc: `${publicPath}/foto/2.jpg`,
      alt: 'Image 3',
      large: false,
      name: 'Andrea Jaglic',
      job: 'Majiteľka cestovnej agentúry',
      answers: [
        <>
          <TextContainer>Čo pre Vás predstavuje ženskú silu?</TextContainer>
          <AnswerContainer>„Ženskú silu cítim v sebe, ale milujem aj to, keď cítim ženskú podporu, vtedy sa moja vnútorná ženská sila zdvojnásobuje.”</AnswerContainer>
        </>,
      ],
    },
    {
      src: `${publicPath}/fototext/3.jpg`,
      modalSrc: `${publicPath}/foto/3.jpg`, // Laura Ciparova
      alt: 'Image 4',
      large: true,
      name: 'Laura Cipárová',
      job: 'Social media creator',
      answers: [
        <>
          <TextContainer>Situácia v ktorej sa cítite najsilnejšia?</TextContainer>
          <AnswerContainer>„Keď sa nebojím prejaviť vo svojej plnej kráse a prijatí seba samej.“</AnswerContainer>
          <TextContainer>Prečo máte radi značku Dajana Rodriguez?</TextContainer>
          <AnswerContainer>„Pretože spája podstivosť, lásku, inakosť.“</AnswerContainer>
        </>,
      ],
    },
    {
      src: `${publicPath}/fototext/4.jpg`, // Barbora Jelinek
      modalSrc: `${publicPath}/foto/4.jpg`,
      alt: 'Image 5',
      large: false,
      name: 'Barbora Jelinek',
      job: 'Social media manager Akčné Ženy',
      answers: [
        <>
          <TextContainer>Čo pre Vás predstavuje ženskú silu?</TextContainer>
          <AnswerContainer>„Žena je ako vrecko čaju. Nikdy nevieš, aké je silné, pokiaľ ho neponoríš do horúcej vody.“</AnswerContainer>
          <TextContainer>Situácia v ktorej sa cítite najsilnejšia?</TextContainer>
          <AnswerContainer>„Keď prekonám samú seba, či vystúpim z komfortnej zóny.“</AnswerContainer>
          <TextContainer>Prečo máte radi značku Dajana Rodriguez?</TextContainer>
          <AnswerContainer>„Lebo je za ňou príbeh ženy. Ženskej odvahy a odhodlania plniť si svoj sen.“</AnswerContainer>
        </>,
      ],
    },
    {
      src: `${publicPath}/fototext/5.jpg`,
      modalSrc: `${publicPath}/foto/5.jpg`, // Judr filipovicova
      alt: 'Image 6',
      large: false,
      name: 'JUDr. Katarína Filipovičová',
      job: 'Advokátka',
      answers: [
        <>
          <TextContainer>Čo pre Vás predstavuje ženskú silu?</TextContainer>
          <AnswerContainer>„...najväčšie hrdinky sú častokrát skryté pred očami verejnosti...“</AnswerContainer>
          <TextContainer>Prečo máte radi značku Dajana Rodriguez?</TextContainer>
          <AnswerContainer>„Pretože charakterizuje ženskú silu“</AnswerContainer>
        </>,
      ],
    },
    {
      src: `${publicPath}/fototext/6.jpg`,
      modalSrc: `${publicPath}/foto/6.jpg`, // Nora Kakosova
      alt: 'Image 7',
      large: true,
      name: 'Nora Kakosova',
      job: 'Influencerka',
      answers: [
        <>
          <TextContainer>Prečo máte radi značku Dajana Rodriguez?</TextContainer>
          <AnswerContainer>„Predstavujem si pod tým ženu, veľmi sebavedomú ženu, ktorá ma na svedomí krásnu značku, ktorá inšpiruje ďalšie ženy.” </AnswerContainer>
        </>,
      ],
    },
    {
      src: `${publicPath}/fototext/7.jpg`,
      modalSrc: `${publicPath}/foto/7.jpg`,
      alt: 'Image 8',
      large: false,
      name: 'Sabina Michňová',
      job: 'Kaderníčka',
      answers: [
        <>
          <TextContainer>Situácia v ktorej sa cítite najsilnejšia?</TextContainer>
          <AnswerContainer>„Najsilnejšia som sa cítila keď som porodila svoju dcéru.“</AnswerContainer>
        </>,
      ],
    },
    {
      src: `${publicPath}/fototext/8.jpg`,
      modalSrc: `${publicPath}/foto/8.jpg`, // Katka reptova
      alt: 'Image 9',
      large: true,
      name: 'Mgr. Katarína Reptová',
      job: 'Sestrička online',
      answers: [
        <>
          <TextContainer>Čo pre Vás predstavuje ženskú silu?</TextContainer>
          <AnswerContainer>„Schopnosť postaviť sa, keď padnete na najhlbšie dno.“</AnswerContainer>
          <TextContainer>Prečo máte radi značku Dajana Rodriguez?</TextContainer>
          <AnswerContainer>„Značka D.R. je elegantná, luxusná, výnimočná a určená pre ženy, ktoré chcú vyniknúť.“</AnswerContainer>
        </>,
      ],
    },
    {
      src: `${publicPath}/fototext/9.jpg`,
      modalSrc: `${publicPath}/foto/9.jpg`,
      alt: 'Image 10',
      large: false,
      name: 'Veronika Kril',
      job: 'Content creator',
      answers: [
        <>
          <TextContainer>Čo pre Vás predstavuje ženskú silu?</TextContainer>
          <AnswerContainer>„Keď sa ženy vedia navzájom podporiť a podržať sa.”</AnswerContainer>
          <TextContainer>Situácia v ktorej sa cítite najsilnejšia?</TextContainer>
          <AnswerContainer>„Keď si žijem svoje sny.”</AnswerContainer>
          <TextContainer>Prečo máte radi značku Dajana Rodriguez?</TextContainer>
          <AnswerContainer>„Lebo je ženská, jemne odvážna a nadčasová zároveň.”</AnswerContainer>
        </>,
      ],
    },
    {
      src: `${publicPath}/fototext/10.jpg`, //Barbora Bobáľová
      modalSrc: `${publicPath}/foto/10.jpg`,
      alt: 'Image 11',
      large: false,
      name: 'Ing. Barbora Bobáľová',
      job: 'Tlmočníčka a prekladateľka',
      answers: [
        <>
          <TextContainer>Čo pre Vás predstavuje ženskú silu?</TextContainer>
          <AnswerContainer>„Ženská sila je odhodlanie stáť si za svojím presvedčením, hodnotami a ambíciami.“</AnswerContainer>
          <TextContainer>Prečo máte radi značku Dajana Rodriguez?</TextContainer>
          <AnswerContainer>„Túto značku som si obľúbila pre jej slovenský pôvod a kvalitu výrobkov.“</AnswerContainer>
        </>,
      ],
    },
    {
      src: `${publicPath}/fototext/11.jpg`,
      modalSrc: `${publicPath}/foto/11.jpg`,
      alt: 'Image 12',
      large: false,
      name: 'Erika Mokrý',
      job: 'Riaditeľka Občianskeho združenia Tak trochu inak',
      answers: [
        <>
          <TextContainer>Prečo máte radi značku Dajana Rodriguez?</TextContainer>
          <AnswerContainer>„Je dokonalým príkladom toho, že sny sa dokážu splniť, treba na nich tvrdo pracovať a byt pri tom sama sebou.“</AnswerContainer>
        </>,
      ],
    },
    {
      src: `${publicPath}/fototext/12.jpg`,
      modalSrc: `${publicPath}/foto/12.jpg`,
      alt: 'Image 13',
      large: false,
      name: 'Diana Porubčanová',
      job: 'Koučka a mentorka',
      answers: [
        <>
          <TextContainer>Čo pre Vás predstavuje ženskú silu?</TextContainer>
          <AnswerContainer>„Ženská sila pre mňa znamená to, že sa ženy ukazujú vo svojej autenticite a také, aké v skutočnosti sú. Nie také, aké ich niekto chce vidieť.“</AnswerContainer>
        </>,
      ],
    },
    {
      src: `${publicPath}/fototext/13.jpg`,
      modalSrc: `${publicPath}/foto/13.jpg`,
      alt: 'Image 14',
      large: false,
      name: 'Adriana Benková',
      job: 'Sestrička',
      answers: [
        <>
          <TextContainer>Čo pre Vás predstavuje ženskú silu?</TextContainer>
          <AnswerContainer>„Ide o hromadu silnej energie, keď aj v ťažkých chvíľach života, sa snaží ísť na plný plyn a nevzdáva sa pred prekážkami. Proste bojuje.“</AnswerContainer>
        </>,
      ],
    },
    {
      src: `${publicPath}/fototext/14.jpg`,
      modalSrc: `${publicPath}/foto/14.jpg`,
      alt: 'Image 15',
      large: false,
      name: 'MUDr. Monika Michňová',
      job: 'Pediatrička',
      answers: [
        <>
          <TextContainer>Čo pre Vás predstavuje ženskú silu?</TextContainer>
          <AnswerContainer>„Zažila som to v práci, keď som musela resuscitovať malé bábätko a podarilo sa to. Takže pre mňa to bol silný moment.”</AnswerContainer>
          <TextContainer>Prečo máte radi značku Dajana Rodriguez?</TextContainer>
          <AnswerContainer>„Za značkou Dajana Rodriguez vidím jednu šikovnú mladú babu, ktorá išla za svojim snom a niečo dosiahla.”</AnswerContainer>
        </>,
      ],
    },
    {
      src: `${publicPath}/fototext/15.jpg`,
      modalSrc: `${publicPath}/foto/15.jpg`,
      alt: 'Image 15',
      large: false,
      name: 'MUDr.Nina Paššáková',
      job: 'Estetický lekár',
      answers: [
        <>
          <TextContainer>Čo pre Vás predstavuje ženskú silu?</TextContainer>
          <AnswerContainer>„Sila ženy pre mňa znamená byť dostatočne silná na to, aby som sa neprispôsobovala okoliu, aby som robila to, čo cítim, čo chcem, a aby ma to robilo šťastnou a naozaj napĺňalo.”</AnswerContainer>
        </>,
      ],
    },
  ];

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <GlobalStyle />
      <WebContainer>
        <HeroSection />
        <Container>
          <FadeInText mobilePosition={{ top: '420px', left: '-10px' }} position={{ top: '420px', left: '-160px' }}>
            {' '}
            odvážná
          </FadeInText>
          <FadeInText fontSize="30px" mobilePosition={{ top: '1100px', left: '30px' }} position={{ top: '1200px', left: '-250px' }}>
            {' '}
            múdra
          </FadeInText>
          <FadeInText fontSize="24px" mobilePosition={{ top: '1800px', left: '0px' }} position={{ top: '1800px', left: '-230px' }}>
            {' '}
            inšpirujúca
          </FadeInText>

          <FadeInText fontSize="32px" mobilePosition={{ top: '250px', right: '0px' }} position={{ top: '850px', right: '-310px' }}>
            {' '}
            optimistická
          </FadeInText>
          <FadeInText fontSize="46px" mobilePosition={{ top: '900px', right: '-30px' }} position={{ top: '1600px', right: '-210px' }}>
            {' '}
            Neistá
          </FadeInText>
          <FadeInText fontSize="44px" mobilePosition={{ top: '2060px', right: '0px' }} position={{ top: '2060px', right: '-280px' }}>
            {' '}
            Netrpezlivá
          </FadeInText>
          <ImageSection />
          <ImageGrid images={imagesBuffer} />

          <LogoButtonContainer>
            <Logo src={logoSvg} alt="Dajana Rodriguez" />
            <CTASection>
              <Button href="https://dajanarodriguez.sk">Prejsť na Dajana Rodriguez</Button>
            </CTASection>
          </LogoButtonContainer>
        </Container>
      </WebContainer>
      <Footer />
    </div>
  );
}

export default App;
