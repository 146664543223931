import styled from 'styled-components';

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (max-width: 568px) {
    object-fit: cover;
    aspect-ratio: 1;
    object-position: center;
  }
`;

const YoutubeVideo = styled.iframe`
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 560 / 315;
  max-width: 900px;
  margin: 0 auto;
  display: flex;

  @media (max-width: 568px) {
    object-fit: cover;
    aspect-ratio: 1;
    object-position: center;
  }
`;

const Title = styled.h2`
  font-size: 60px;
  color: #753bbd;
  line-height: 1.25;
  margin: 0 auto;
  text-align: center;

  @media only screen and (max-width: 968px) {
    font-size: 42px;
    text-align: left;
    margin: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 968px) {
    gap: 24px;
    text-align: left;
  }

  p {
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
    line-height: 1.75;

    @media (max-width: 968px) {
      text-align: left;
      line-height: 1.5;
    }
  }
`;

const Container = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 100%;
  margin: 0 auto;
  padding: 24px 0 64px;

  @media (max-width: 968px) {
    gap: 24px;
    padding: 24px 0 24px;
  }

  ${YoutubeVideo} {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;

    @media (max-width: 968px) {
      width: 100%;
    }
  }

  ${Content} {
    width: 100%;

    @media (max-width: 968px) {
      width: 100%;
    }
  }
`;

const ImageSection = () => {
  return (
    <Container>
      <Content>
        <Title>Buď...</Title>
        <p>
          My, v Dajana Rodriguez, s hrdosťou podporujeme všetky ženy, nech sú akékoľvek. Možno ste si všimli, možno nie, ale od minulého roku sme sa vyfarbili do krásnej fialovej, pretože je to farba energická, umelecká a ženská. My sme
          značka, ktorá je o ženách a pre ženy. Podporujeme všetky ženy, bez ohľadu na vek, postavu či povolanie. Či už si učiteľka, právnička, herečka alebo mama na materskej, my veríme, že máš právo byť čím chceš. Buď odvážná, buď
          unavená, buď nahnevaná, buď šťastná, buď sebavedomá - jednoducho buď. Sme tu pre vás a veríme, že každá z vás má silu dosiahnuť svoje sny a premeniť svet okolo seba.
        </p>
        {/*<HeroButton href="https://dajanarodriguez.sk/account/signup">Zaregistruj sa</HeroButton>*/}
      </Content>
      <YoutubeVideo
        src="https://www.youtube.com/embed/dfh2ezaw69g?si=8YWgvDVvCKrZ1_dj"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></YoutubeVideo>
    </Container>
  );
};

export default ImageSection;
