import React from 'react';
import styled from 'styled-components';
import logoSvg from '../img/logo.svg';
import bannerImg from '../img/banner.jpg';
import bannerMobileImg from '../img/banner-mobil.jpg';

const StyledHeroSection = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #7b3fb9;
`;

const Logo = styled.img`
  padding: 16px;

  @media (max-width: 768px) {
    width: 256px;
  }
`;

const ImageBackground = styled.img`
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  object-fit: cover;

  @media (max-width: 968px) {
    aspect-ratio: 1280/1491;
    height: auto;
  }
`;

const StyledPicture = styled.picture`
  width: 100%;
  height: 100%;

  @media (max-width: 968px) {
    aspect-ratio: 1280/1491;
  }
`;

export const HeroSection = () => {
  return (
    <StyledHeroSection>
      <Logo src={logoSvg} alt="logo" />
      <StyledPicture>
        <source width="2560" height="1383" media="(min-width: 968px)" srcSet={bannerImg} type="image/jpg" />
        <source width="1280" height="1491" media="(max-width: 967.98px)" srcSet={bannerMobileImg} type="image/jpg" />
        <ImageBackground width="2560" height="1383" src={bannerImg} alt="banner" />
      </StyledPicture>
    </StyledHeroSection>
  );
};
